
import { Component, Prop, Vue } from 'vue-property-decorator';

import KeyCode from '@/shared/enums/KeyCode';

@Component
export default class Modal extends Vue {
  @Prop({ required: true }) readonly show!: boolean

  created() {
    document.addEventListener('keyup', this.escapeHandler);
  }

  destroyed() {
    document.removeEventListener('keyup', this.escapeHandler);
  }

  escapeHandler({ which }: UIEvent): void {
    if (which === KeyCode.esc && this.show) {
      this.$emit('close');
    }
  }
}
