
import {
  Component, Model, Prop, Watch, Vue,
} from 'vue-property-decorator';

import Modal from './Modal.vue';

@Component({ components: { Modal } })
export default class PaymentModal extends Vue {
  $refs!: {
    iframe: HTMLIFrameElement;
  }

  @Model('close', { type: Boolean }) readonly open!: boolean

  @Prop({ required: true }) readonly url!: string

  get targetWindow(): Window | null {
    // getter to be used by the parent.
    return this.$refs.iframe.contentWindow;
  }

  @Watch('open')
  onOpenChange(val: boolean) {
    const { iframe } = this.$refs;

    iframe.src = val ? this.url : '';
  }
}
