enum EventType {
  bankSlipCompletePurchase = 'boleto-complete-purchase',
  bankSlipCopy = 'boleto-copy',
  checkoutDismissed = 'checkout-dismissed',
  checkoutError = 'checkout-error',
  checkoutReady = 'checkout-ready',
  checkoutSuccess = 'checkout-success',
  emv3dsChallengeSuppression = '3ds-challenge-suppression',
  emv3dsDisabled = '3ds-disabled',
  emv3dsError = '3ds-error',
  emv3dsFailure = '3ds-failure',
  emv3dsReady = '3ds-ready',
  emv3dsSuccess = '3ds-success',
  emv3dsUnenrolled = '3ds-unenrolled',
  emv3dsUnsupportedBrand = '3ds-unsupported-brand',
  pixCompletePurchase = 'pix-complete-purchase',
  pixCopy = 'pix-copy',
  oxxoCopy = 'oxxo-copy',
  oxxoCompletePurchase = 'oxxo-complete-purchase',
  paynetCopy = 'paynet-copy',
  paynetCompletePurchase = 'paynet-complete-purchase',
  speiCopy = 'spei-copy',
  speiCompletePurchase = 'spei-complete-purchase',
  paycashCopy = 'paycash-copy',
  paycashCompletePurchase = 'paycash-complete-purchase',
  picpayCompletePurchase = 'picpay-complete-purchase',
}

export default EventType;
