enum PaymentMode {
  creditCard = 'CREDITCARD',
  bankSlip = 'BOLETO',
  debitCard = 'DEBITCARD',
  pix = 'PIX',
  oxxo = 'OXXO',
  paynet = 'PAYNET',
  spei = 'SPEI',
  paycash = 'PAYCASH',
  picpay = 'PICPAY',
  bankTransfer = 'BANK_TRANSFER',
}

export default PaymentMode;
