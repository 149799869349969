
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PaymentAction extends Vue {
  @Prop({ default: false }) readonly disabled!: boolean;

  @Prop({ required: true }) readonly buttonLabel!: string;

  @Prop({ required: true }) readonly buttonColor!: string;

  @Prop({ required: true }) readonly buttonTextColor!: string;
}
