
import { Component, Mixins, Prop } from 'vue-property-decorator';

import EventType from '@/shared/enums/EventType';
import MessageType from '@/shared/enums/MessageType';
import PaymentMode from '@/shared/enums/PaymentMode';

import PostMessageMixin, { Message } from '@/shared/mixins/post_message/PostMessageMixin';

import isMobile from './utils/isMobile';

import PaymentAction from './components/PaymentAction.vue';
import PaymentModal from './components/PaymentModal.vue';

import './EpagWidget';

@Component({
  components: {
    PaymentModal,
    PaymentAction,
  },
  props: {
    closeLightbox: {
      type: Function,
      default() {
        const { vueComponent } = this as unknown as { vueComponent: EpagWidget };
        vueComponent.closePayment();
      },
    },
  },
})
export default class EpagWidget extends Mixins(PostMessageMixin) {
  $refs!: {
    paymentModal: PaymentModal;
  }

  modalOpen = false

  popup: Window | null = null

  @Prop({ default: false, type: Boolean }) readonly hidePaymentAction!: boolean

  @Prop({ type: Number }) readonly amount!: number

  @Prop() readonly referenceId!: string

  @Prop() readonly contractId!: string

  @Prop() readonly asset!: string

  @Prop() readonly personFirstName!: string

  @Prop() readonly personSurname!: string

  @Prop() readonly personEmail!: string

  @Prop() readonly personTaxId!: string

  @Prop() readonly personBirth!: string

  @Prop({ type: Number, default: 55 }) readonly personPhoneCountry!: number

  @Prop({ type: Number }) readonly personPhoneArea!: number

  @Prop() readonly personPhone!: string

  @Prop({ default: true, type: Boolean }) readonly disableDob!: boolean

  @Prop() readonly lockEmail!: boolean

  @Prop({ default: false, type: Boolean }) readonly pixDirect!: boolean

  @Prop() readonly addressMain!: string

  @Prop({ type: Number }) readonly addressNumber!: number

  @Prop() readonly addressAdditional!: string

  @Prop() readonly addressLocality!: string

  @Prop() readonly addressCity!: string

  @Prop() readonly addressState!: string

  @Prop({ default: 'BR' }) readonly addressCountry!: string

  @Prop() readonly addressZipCode!: string

  @Prop() readonly method!: PaymentMode

  @Prop({ default: 1, type: Number }) readonly cardInstallments!: number

  @Prop() readonly boletoExpirationDate!: string

  @Prop() readonly boletoLine1!: string

  @Prop() readonly boletoLine2!: string

  @Prop() readonly boletoLine3!: string

  @Prop() readonly expirationDate!: string

  @Prop() readonly description!: string

  @Prop() readonly notificationUrl!: string

  @Prop() readonly supportText!: string

  @Prop({ default: 'Pay now' }) readonly buttonLabel!: string

  @Prop() readonly lightboxLogoUrl!: string

  @Prop({ default: process.env.VUE_APP_THEME_BUTTON_COLOR })
  readonly buttonColor!: string

  @Prop({ default: process.env.VUE_APP_THEME_BUTTON_TEXT_COLOR })
  readonly buttonTextColor!: string

  @Prop({ default: process.env.VUE_APP_THEME_PRIMARY_COLOR })
  readonly lightboxPrimaryColor!: string

  @Prop({ default: process.env.VUE_APP_THEME_SECONDARY_COLOR })
  readonly lightboxSecondaryColor!: string

  @Prop({ default: process.env.VUE_APP_THEME_ACCENT_COLOR })
  readonly lightboxAccentColor!: string

  @Prop({ default: process.env.VUE_APP_THEME_POSITIVE_COLOR })
  readonly lightboxPositiveColor!: string

  @Prop({ default: process.env.VUE_APP_THEME_NEGATIVE_COLOR })
  readonly lightboxNegativeColor!: string

  @Prop({ default: process.env.VUE_APP_THEME_INFO_COLOR })
  readonly lightboxInfoColor!: string

  @Prop({ default: process.env.VUE_APP_THEME_WARNING_COLOR })
  readonly lightboxWarningColor!: string

  @Prop({ default: process.env.VUE_APP_THEME_ADDRESS_BAR_COLOR })
  readonly lightboxAddressBarColor!: string

  @Prop({ default: 'Concluir compra' })
  readonly lightboxCompletePurchaseButtonLabel!: string

  @Prop({ default: false, type: Boolean })
  readonly lightboxUseMobile!: boolean

  @Prop({ default: false, type: Boolean })
  readonly lightboxDisableAddress!: boolean

  @Prop({ default: false, type: Boolean })
  readonly lightboxPartialAddress!: boolean

  @Prop({ default: false, type: Boolean })
  readonly lightboxUse3ds!: boolean

  @Prop({ default: false, type: Boolean })
  readonly lightboxUseExternal3ds!: boolean

  @Prop({ default: false, type: Boolean })
  readonly processPayment!: boolean

  @Prop({ default: false, type: Boolean })
  readonly delayCapture!: boolean

  @Prop({ default: true, type: Boolean })
  readonly saveCard!: boolean

  @Prop({ type: String })
  readonly softDescriptor?: string

  @Prop({ default: 'BR', type: String })
  readonly paymentCountry!: string

  @Prop({ default: 'BRL', type: String })
  readonly paymentCurrency!: string

  @Prop({ type: String })
  readonly returnUrl!: string

  get targetOrigin(): string {
    return process.env.VUE_APP_CHECKOUT_URL;
  }

  get checkoutUrl(): string {
    return `${this.targetOrigin}?url=${window.location.origin}`;
  }

  get isMobile(): boolean {
    return isMobile();
  }

  get targetWindow(): Window | null {
    return this.$refs.paymentModal.targetWindow;
  }

  payNow(): void {
    this.validateProps();
    this.modalOpen = true;
  }

  closePayment() {
    if (this.isMobile && this.popup && !this.popup.closed) {
      this.popup.close();
    }
    this.popup = null;
    this.modalOpen = false;
    this.$emit(EventType.checkoutDismissed);
  }

  receiveMessage(message: Message) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { closeLightbox, ...data } = this.$props;

    switch (message.type) {
      case MessageType.ready:
        this.sendMessage(MessageType.initData, data);
        this.$emit(EventType.checkoutReady);
        break;

      case MessageType.closePayment:
        this.closePayment();
        break;

      case MessageType.checkoutSuccess:
        this.$emit(EventType.checkoutSuccess, message.payload);
        break;

      case MessageType.checkoutError:
        this.$emit(EventType.checkoutError, message.payload);
        break;

      case MessageType.bankSlipCopy:
        this.$emit(EventType.bankSlipCopy);
        break;

      case MessageType.bankSlipCompletePurchase:
        this.$emit(EventType.bankSlipCompletePurchase);
        this.closePayment();
        break;

      case MessageType.emv3dsSuccess:
        this.$emit(EventType.emv3dsSuccess, message.payload);
        break;

      case MessageType.emv3dsUnenrolled:
        this.$emit(EventType.emv3dsUnenrolled, message.payload);
        break;

      case MessageType.emv3dsUnsupportedBrand:
        this.$emit(EventType.emv3dsUnsupportedBrand, message.payload);
        break;

      case MessageType.emv3dsChallengeSuppression:
        this.$emit(EventType.emv3dsChallengeSuppression, message.payload);
        break;

      case MessageType.emv3dsDisabled:
        this.$emit(EventType.emv3dsDisabled, message.payload);
        break;

      case MessageType.emv3dsError:
        this.$emit(EventType.emv3dsError, message.payload);
        break;

      case MessageType.emv3dsFailure:
        this.$emit(EventType.emv3dsFailure, message.payload);
        break;

      case MessageType.emv3dsReady:
        this.$emit(EventType.emv3dsReady, message.payload);
        break;

      case MessageType.pixCopy:
        this.$emit(EventType.pixCopy);
        break;

      case MessageType.pixCompletePurchase:
        this.$emit(EventType.pixCompletePurchase);
        this.closePayment();
        break;

      case MessageType.oxxoCopy:
        this.$emit(EventType.oxxoCopy);
        break;

      case MessageType.oxxoCompletePurchase:
        this.$emit(EventType.oxxoCompletePurchase);
        this.closePayment();
        break;

      case MessageType.speiCopy:
        this.$emit(EventType.speiCopy);
        break;

      case MessageType.speiCompletePurchase:
        this.$emit(EventType.speiCompletePurchase);
        this.closePayment();
        break;
      case MessageType.paynetCopy:
        this.$emit(EventType.paynetCopy);
        break;

      case MessageType.paynetCompletePurchase:
        this.$emit(EventType.paynetCompletePurchase);
        this.closePayment();
        break;

      case MessageType.paycashCopy:
        this.$emit(EventType.paycashCopy);
        break;

      case MessageType.paycashCompletePurchase:
        this.$emit(EventType.paycashCompletePurchase);
        this.closePayment();
        break;

      case MessageType.picpayCompletePurchase:
        this.$emit(EventType.picpayCompletePurchase);
        this.closePayment();
        break;

      default:
        break;
    }
  }

  validateProps(): never | void {
    const requiredProps = ['amount', 'referenceId', 'contractId', 'method', 'asset'];

    if (this.lightboxUse3ds) {
      requiredProps.push('notificationUrl');
    }

    requiredProps.forEach((propName: string) => {
      if (!this.$props[propName]) {
        throw new Error(`Missing required prop: "${propName}"`);
      }
    });

    if (!this.lightboxUse3ds && !this.lightboxUseExternal3ds && this.method === PaymentMode.debitCard) {
      throw new Error('DEBITCARD method is only allowed when using 3DS');
    }

    if ((this.lightboxUse3ds || this.lightboxUseExternal3ds) && ![PaymentMode.creditCard, PaymentMode.debitCard].includes(this.method)) {
      throw new Error('When using 3DS only CREDITCARD and DEBITCARD methods are allowed');
    }

    if (this.processPayment && this.method !== PaymentMode.creditCard && this.method !== PaymentMode.debitCard) {
      throw new Error('When enabled to process payment only CREDITCARD and DEBITCARD methods are allowed');
    }

    if (this.pixDirect && this.method !== PaymentMode.pix) {
      throw new Error('When using PIX Direct only PIX method is allowed');
    }
  }
}
