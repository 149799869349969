/* eslint-disable no-mixed-operators */
const uagent = navigator && navigator.userAgent;

const isSailfish = () => /sailfish/i.test(uagent);

const isUbuntuTablet = () => /ubuntu/i.test(uagent) && /tablet/i.test(uagent);

const isWebOSTablet = () => /hpwos/i.test(uagent) && /tablet/i.test(uagent);

const isOperaMobile = () => /opera/i.test(uagent) && (/mini/i.test(uagent) || /mobi/i.test(uagent));

const isGoogleTV = () => /googletv/i.test(uagent);

const isAmazonSilk = () => /silk-accelerated/i.test(uagent);

const isBrewDevice = () => /brew/i.test(uagent);

const isWindowsPhone7 = () => /windows phone os 7/i.test(uagent);

const isWindowsPhone8 = () => /windows phone 8/i.test(uagent);

const isWindowsPhone10 = () => /windows phone 10/i.test(uagent);

const isBlackBerry10Phone = () => /bb10/i.test(uagent) && /mobile/i.test(uagent);

const isPalmWebOS = () => /webos/i.test(uagent);

const isBada = () => /bada/i.test(uagent);

const isTizen = () => /tizen/i.test(uagent) && /mobile/i.test(uagent);

const isWebkit = () => /webkit/i.test(uagent);

const isBlackBerryTablet = () => /playbook/i.test(uagent);

const isIpod = () => /ipod/i.test(uagent);

const isUbuntuPhone = () => /ubuntu/i.test(uagent) && /mobile/i.test(uagent);

const isGamingHandheld = () => /playstation/i.test(uagent) && /vita/i.test(uagent);

const isMeegoPhone = () => /meego/i.test(uagent) && /mobi/i.test(uagent);

const isS60OssBrowser = () => isWebkit() && (/series60/i.test(uagent) || /symbian/i.test(uagent));

const isBlackBerry = () => (/blackberry/i.test(uagent) || /vnd.rim/.test(uagent)) || isBlackBerry10Phone();

const isBlackBerryWebKit = () => isBlackBerry() && /webkit/i.test(uagent);

const isIpad = () => /ipad/i.test(uagent) && isWebkit();

const isAndroid = () => /android/i.test(uagent) || isGoogleTV();

const isKindle = () => /kindle/i.test(uagent) && !isAndroid();

const isIphone = () => /iphone/i.test(uagent) && !isIpad() && !isIpod();

const isSailfishPhone = () => isSailfish() && /mobile/i.test(uagent);

const isIphoneOrIpod = () => isIphone() || isIpod();

const isIos = () => isIphoneOrIpod() || isIpad();

const isAndroidTablet = () => {
  if (!isAndroid()) {
    return false;
  }
  if (isOperaMobile()) {
    return false;
  }
  return !/mobile/i.test(uagent);
};

const isFirefoxOSTablet = () => {
  if (isIos() || isAndroid() || isSailfish()) {
    return false;
  }

  return /firefox/i.test(uagent) && /tablet/i.test(uagent);
};

const isAndroidPhone = () => {
  if (!isAndroid()) {
    return false;
  }

  return /mobile/i.test(uagent) || isOperaMobile();
};

const isFirefoxOSPhone = () => {
  if (isIos() || isAndroid() || isSailfish()) {
    return false;
  }

  return /firefox/i.test(uagent) && /mobile/i.test(uagent);
};

const isBlackBerryTouch = () => (
  !isBlackBerry()
  && /blackberry95/i.test(uagent)
  || /blackberry 98/i.test(uagent)
  || /blackberry 99/i.test(uagent)
  || /blackberry 938/i.test(uagent)
);

const isSymbianOS = () => (
  /symbian/i.test(uagent)
  || /series60/i.test(uagent)
  || (/symbos/i.test(uagent) && isOperaMobile())
  || /series70/i.test(uagent)
  || /series80/i.test(uagent)
  || /series90/i.test(uagent)
);

const isPalmOS = () => (
  !isPalmWebOS()
  && /palm/i.test(uagent)
  || /blazer/i.test(uagent)
  || /xiino/i.test(uagent)
);

const isTierTablet = () => (
  isIpad()
  || isAndroidTablet()
  || isBlackBerryTablet()
  || isFirefoxOSTablet()
  || isUbuntuTablet()
  || isWebOSTablet()
);

const isWindowsPhone = () => (
  isWindowsPhone7()
  || isWindowsPhone8()
  || isWindowsPhone10()
);

const isWindowsMobile = () => {
  if (isWindowsPhone()) {
    return false;
  }

  return (
    (/windows ce/i.test(uagent) || /iemobile/i.test(uagent) || /wm5 pie/i.test(uagent))
    || (/ppc/i.test(uagent) && !/macintosh/i.test(uagent))
    || (/htc/i.test(uagent) && /windows/i.test(uagent))
  );
};

const isTierIphone = () => (
  isIphoneOrIpod()
  || isAndroidPhone()
  || isWindowsPhone()
  || isBlackBerry10Phone()
  || isPalmWebOS()
  || isBada()
  || isTizen()
  || isFirefoxOSPhone()
  || isSailfishPhone()
  || isUbuntuPhone()
  || isGamingHandheld()
  || (isBlackBerryWebKit() && isBlackBerryTouch())
);

const isSmartPhone = () => (
  isTierIphone()
  || isS60OssBrowser()
  || isSymbianOS()
  || isWindowsMobile()
  || isBlackBerry()
  || isMeegoPhone()
  || isPalmOS()
);

export default function isMobile() {
  if (isTierTablet()) {
    return false;
  }
  if (isSmartPhone()) {
    return true;
  }
  if (/mobile/i.test(uagent)) {
    return true;
  }
  if (isOperaMobile()) {
    return true;
  }
  if (isKindle() || isAmazonSilk()) {
    return true;
  }
  if (/midp/i.test(uagent) || isBrewDevice()) {
    return true;
  }
  if (/obigo/i.test(uagent) || /netfront/i.test(uagent) || /up.browser/i.test(uagent)) {
    return true;
  }
  return false;
}
