enum MessageType {
  bankSlipCompletePurchase,
  bankSlipCopy,
  checkoutError,
  checkoutSuccess,
  closePayment,
  emv3dsChallengeSuppression,
  emv3dsDisabled,
  emv3dsError,
  emv3dsFailure,
  emv3dsReady,
  emv3dsSuccess,
  emv3dsUnenrolled,
  emv3dsUnsupportedBrand,
  initData,
  pixCompletePurchase,
  pixCopy,
  ready,
  oxxoCopy,
  oxxoCompletePurchase,
  paynetCopy,
  paynetCompletePurchase,
  speiCopy,
  speiCompletePurchase,
  paycashCopy,
  paycashCompletePurchase,
  picpayCompletePurchase,
}

export default MessageType;
