import { Component, Vue } from 'vue-property-decorator';

import MessageType from '@/shared/enums/MessageType';
import Message from './Message';

@Component
export default class PostMessageMixin extends Vue {
  get targetOrigin(): string | null {
    return null;
  }

  get targetWindow(): Window | null {
    return null;
  }

  created() {
    window.addEventListener('message', this.listenToMessage, false);
  }

  destroyed() {
    window.removeEventListener('message', this.listenToMessage);
  }

  listenToMessage({ origin, source, data }: MessageEvent) {
    if (origin !== this.targetOrigin || source !== this.targetWindow) return;

    this.receiveMessage(data);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  receiveMessage(message: Message): void {
    /* Override me :) */
  }

  sendMessage(type: MessageType, payload: unknown = null): void {
    if (this.targetOrigin && this.targetWindow) {
      const message = new Message(type, payload);
      this.targetWindow.postMessage(message, this.targetOrigin);
    }
  }
}

export { Message };

