import EventType from '@/shared/enums/EventType';

import CheckoutConfig from '@/shared/models/CheckoutConfig';

type Listener = (event: Event) => void

interface CustomElement extends HTMLElement {
  _wrapper: { _isMounted: boolean };
  vueComponent: {
    $nextTick: Function;
    payNow: Function;
    closePayment: Function;
  };
}

interface CheckoutInterface {
  mountIn(container: HTMLElement | string): void;
  start(): void;
  closeLightbox(): void;
  on(type: EventType, listener: Listener): void;
  off(type: EventType, listener: Listener): void;
}

declare global {
  interface Window { [key: string]: unknown }
}

class Checkout implements CheckoutInterface {
  private readonly el: CustomElement

  constructor(private readonly config: CheckoutConfig) {
    this.el = document.createElement(process.env.VUE_APP_NAME);
  }

  private settingUpAttributes() {
    Object.assign(this.el, this.config);
  }

  mountIn(container: Element | string) {
    if (typeof container === 'string') {
      container = Checkout.findElement(container); // eslint-disable-line no-param-reassign
    }
    container.append(this.el);
    this.settingUpAttributes();
  }

  start() {
    const { _wrapper: { _isMounted: isMounted } } = this.el;
    if (!isMounted) {
      Object.assign(this.config, { hidePaymentAction: true });
      this.mountIn(document.body);
    }

    const { vueComponent } = this.el;
    vueComponent.$nextTick(() => vueComponent.payNow());
  }

  closeLightbox() {
    const { _wrapper: { _isMounted: isMounted } } = this.el;
    if (isMounted) {
      this.el.vueComponent.closePayment();
    }
  }

  on(type: EventType, listener: Listener) {
    if (arguments.length < 2) {
      throw new TypeError(`Failed to execute '.on()' on 'EventTarget': 2 arguments required, but only ${arguments.length} present.`);
    }
    if (!(listener instanceof Object)) {
      throw new TypeError('Failed to execute \'.on()\' on \'EventTarget\': The callback provided as parameter 2 is not an object.');
    }
    this.el.addEventListener(type, listener);
  }

  off(type: EventType, listener: Listener) {
    if (arguments.length < 2) {
      throw new TypeError(`Failed to execute '.off()' on 'EventTarget': 2 arguments required, but only ${arguments.length} present.`);
    }
    if (!(listener instanceof Object)) {
      throw new TypeError('Failed to execute \'.off()\' on \'EventTarget\': The callback provided as parameter 2 is not an object.');
    }
    this.el.removeEventListener(type, listener);
  }

  static findElement(selector: string): Element | never {
    const element = document.querySelector(selector);
    if (!element) {
      throw new Error(`Element with selector '${selector}' not found`);
    }
    return element;
  }
}

const name = (process.env.VUE_APP_NAME === 'epag-checkout') ? 'EPagCheckout' : 'LetpayCheckout';
window[name] = Checkout;
